<template>
  <div class="check">
    <breadcrumb v-if="currentTabs == 'room-list'" :breadcrumbList="roomBreadcrumbList"></breadcrumb>
    <breadcrumb v-else :breadcrumbList="physiotherapyBreadcrumbList"></breadcrumb>
    <div class="check-content">
      <top-content @scrollRemoveFixed="scrollRemoveFixed" @scrollFixed="scrollFixed" ref="topFormRef"
        @currentTabsChange="currentTabsChange"></top-content>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { roomBreadcrumbList, physiotherapyBreadcrumbList } from './config'
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import TopContent from './cpns/top-content/top-content.vue'
export default defineComponent({
  props: {

  },
  components: {
    breadcrumb,
    TopContent
  },
  setup() {
    const currentTabs = ref('room-list')
    const currentTabsChange = (value) => {
      currentTabs.value = value
    }

    const topFormRef = ref()

    // const scrollRemoveFixed = () => {
    //   if (topFormRef.value.$el.classList.contains('topformFiexed') == true) {
    //     topFormRef.value.$el.classList.remove('topformFiexed')
    //   }
    // }

    // const scrollFixed = () => {
    //   // console.log('scrollFixed ');
    //   if (topFormRef.value.$el.classList.contains('topformFiexed') == false) {
    //     topFormRef.value.$el.classList.add('topformFiexed')
    //   }
    // }


    return {
      // scrollRemoveFixed,
      // scrollFixed,
      topFormRef,
      roomBreadcrumbList,
      physiotherapyBreadcrumbList,
      currentTabsChange,
      currentTabs
    }

  }
})
</script>

<style scoped lang="less">
.check {
  .check-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
    min-width: 1080px;
  }
}
</style>




