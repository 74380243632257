<template>
  <div v-if="currentDialog.isOccupancy">
    <!-- <p style="margin-bottom:10px">{{ currentDialog.causeOccupation }}</p> -->
    <el-form label-position="left"
        ref="ruleFormRef"
        :model="occupyForm"
        label-width="120px"
        class="demo-ruleForm"
        disabled
        status-icon>
        <el-form-item label="理疗师:">
            {{ occupyForm.empName }}
        </el-form-item>
        <el-form-item label="开始日期:"
            prop="date">
            <el-form-item prop="date">
                <el-date-picker :disabled-date="disabledDate"
                    :clearable="false"
                    @change="handleDateChange"
                    v-model="occupyForm.date"
                    type="date"
                    placeholder="选择日期" />
            </el-form-item>
        </el-form-item>
        <el-form-item label="起始时间:"
            prop="startTime">
            <el-select v-model="occupyForm.startTime" placeholder="请选择">
              <el-option
                v-for="(item,index) in teacherTimeList"
                :key="index"
                :label="item.time"
                :value="item.time">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item prop="endTime" label="结束时间">
            <el-select v-model="occupyForm.endTime" placeholder="请选择">
              <el-option
                v-for="(item,index) in teacherTimeList"
                :key="index"
                :label="item.time"
                :value="item.time">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item prop="cause" label="原因">
            <el-select v-model="occupyForm.cause" placeholder="请选择">
              <el-option
                v-for="(item,index) in OccupyTypeOptions"
                :key="index"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
    </el-form>
    <div class="dialog-footer" v-if="!occupyForm.flag">
      <el-button type="warning" @click="cancelOccupancy">取消占用</el-button>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, watchEffect, reactive ,computed} from 'vue'
import { COMPANY_ID } from '@/common/constant'
import { getCustomerInfoList, addCustomerInfo, cancelOccupancys } from '@/service/main/reserve'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ElMessage } from "element-plus"
import {OccupyType} from '../config/occupyType.js'
import dayjs from 'dayjs'

export default defineComponent({
  props: {
    currentDialog: {
      type: Object
    },
    roomOptions: {
      type: Array
    }
  },
  emits: ['makeABillAction', 'confirmAppAction', 'handleReserveDetailClick', 'cancelOccupancy','cancelAppAction'],
  setup (props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const bedOptions = ref([])
    const confirmAppParamsCopy = reactive({
      // appNo: "",
      // companyId: COMPANY_ID,
      "roomId": "",
      // "arvTime": "",
      // "arvKind": "1", // 1.本人，2他人 默认1
      // "arvPerson": "",
      "bed": ""
    })
    const OccupyTypeOptions = new OccupyType().toObjectArray();
    const occupyForm =computed(() => props.currentDialog)
    const handleReserveDetailClick = (currentDialog) => {
      emit("handleReserveDetailClick", currentDialog)
    }
    const roomChage = (e) => {
      if ( !(props.roomOptions&&props.roomOptions.length)) return;
      confirmAppParamsCopy.bed=""
      const index = props.roomOptions.findIndex(r => r.roomId == e)
      bedOptions.value = props.roomOptions[index].bedList
    }
    const confirmAppAction = () => {
      emit("confirmAppAction", confirmAppParamsCopy)
    }
    const makeABillAction = () => {
      emit("makeABillAction")
    }
    const cancelAppAction = () => {
      emit("cancelAppAction", props.currentDialog.id)
    }
    const reserveRemarkList = ref([])

    const initPage = async () => {
      if (!props.currentDialog.isOccupancy) {
        const { data } = await getCustomerInfoList(props.currentDialog.userId)
        reserveRemarkList.value = data.list
      }
    }
    initPage()

    const memo = ref("")
    const handleSubmitGenjinClick = async () => {
      if (!memo.value.trim()) return
      const { orderNo } = props.currentDialog
      let obj = {
        orderNo,
        memo: memo.value
      }
      await addCustomerInfo(obj)
      initPage()
      memo.value = ""
    }

    const handleUsernameClick = () => {
      let { userId, phone } = props.currentDialog
      router.push({
        path: "/main/customer/detail",
        query: {
          userId,
          phone: phone
        }
      })
    }
    const handleOpenKaikaClick = () => {
      let { userId, phone , empId , empName ,shopId } = props.currentDialog
      router.push({
        path: '/main/order/openCard',
        query: {
          userId: userId,
          phone: phone,
          empId,
          empName,
          shopId
        }
      })
    }

    const cancelOccupancy = async () => {
      const res = await cancelOccupancys(props.currentDialog.occupancyNo)
      if (res.code == 0) {
        ElMessage({
          message: '操作成功!',
          type: 'success',
        })
        emit('cancelOccupancy')
      }
    }

    watchEffect(() => {
      const { isOccupancy,bed, roomId } = props.currentDialog
      if (!isOccupancy) {
        if (roomId) roomChage(roomId)
        Object.assign(confirmAppParamsCopy, {
          bed: bed == 0 ? "" : bed,
          roomId: roomId == 0 ? "" : roomId,
          id:props.currentDialog.id
        })
      }
      initPage()
    })
    return {
      memo,
      confirmAppParamsCopy,
      handleReserveDetailClick,
      roomChage,
      bedOptions,
      confirmAppAction,
      makeABillAction,
      handleSubmitGenjinClick,
      reserveRemarkList,
      handleUsernameClick,
      handleOpenKaikaClick,
      cancelOccupancy,
      cancelAppAction,
      occupyForm,
      OccupyTypeOptions
    }
  }
})
</script>
<style lang="less" scoped>
.flex_box{
  // display: flex;
  // flex-direction: column;
  // justify-content: space-around;
  // height: 300px;
}
.isPoint {
  flex-shrink: 0;
  margin-left: 6px;
  width: 50px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid #fd678b;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fd678b;
  line-height: 16px;
  text-align: center;
}

.current-tips {
  color: rgba(80, 80, 80, 1);
  background-color: rgba(239, 239, 239, 1);
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0;

  div {
    line-height: 30px;
    text-align: center;
  }
}

.imgs {
  display: flex;
  justify-content: space-between;

  .user-name {
    cursor: pointer;
    color: rgb(64, 158, 255);
  }

  .name {
    color: rgba(80, 80, 80, 1);
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  .phone {
    color: rgba(80, 80, 80, 1);
    font-size: 14px;
    margin-top: 4px;
  }

}

.img-left {
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.diag-title {
  line-height: 30px;
  width: 380px;
  margin: 0px auto;
  padding-top: 20px;
}

.diag-select {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;

  .el-select {
    // margin-right: 5px;
  }
}

.dialog-footer {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
}
</style>