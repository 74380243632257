<template>
  <bottom-tips></bottom-tips>
  <div ref="sraffNameRef" class="title-wrap room-title">
      <template :key="item" v-for="item in roomReserveList">
        <div class="date-item">
          房间{{ item.roomNo }}-{{ item.bedId }}
        </div>
      </template>
    </div>
  <div class="physiotherapy-list">
    <div class="time">
      <div ref="timeScaleRef" class="time-scale">
        <template v-if="ruleForm.timeDiff == 10">
          <template :key="item" v-for="item in reserveTimeList">
            <div>
              {{ item.time }}
            </div>
          </template>
        </template>

        <template v-if="ruleForm.timeDiff == 30">

          <template :key="item" v-for="item in reserveTimeList">
            <div v-if="item.thirty"> <!---->
              {{ item.time }}
            </div>
          </template>
        </template>

        <template v-if="ruleForm.timeDiff == 60">

          <template :key="item" v-for="item in reserveTimeList">
            <div v-if="item.sixty"> <!---->
              {{ item.time }}
            </div>
          </template>
        </template>
      </div>
      <div ref="mainContentRef" class="line-list" :class="'list-'+ruleForm.timeDiff" v-if="reserveTimeList.length && roomReserveList.length">
        <template :key="indey" v-for="(t, indey) in reserveTimeList">
          <div class="line-box" :style="{height: getH() + 'px'}" :class="{ 'last': indey === reserveTimeList.length - 1 }">
            <!--v-if="checkIsShowLine(indey)"-->
            <template :key="index" v-for="(item, index) in roomReserveList">
              <!-- 已超时 -->
              <template v-if="getLineItem(indey, index).expire">
                <div class="line-item cursor">
                  <div v-if="getLineItem(indey, index).status == 0 || getLineItem(indey, index).status == 4"
                    class="reserve-item restTime" :style="{
                      height: getH() + 'px',
                      top: indey * getH() + 'px',
                      left: index * 112 + 'px',
                    }">
                    <!-- <span>休息</span> -->
                  </div>
                  <!-- <div v-if="getLineItem(indey, index).status == 3" class="reserve-item occupyTime" :style="{
                      height: '72px',
                      top: indey * 72 + 'px',
                      left: index * 112 + 'px',
                    }">
                      已占用
                    </div> -->
                </div>
              </template>
              <template v-else>
                <div class="line-item cursor"
                  :style="{height: getH() + 'px'}"
                  :class="{ paibanActive: getLineItem(indey, index).status == 0 && getLineItem(indey, index).scheduling }"
                  @mouseleave.stop="handleReserveLeave" @mouseenter.stop="handleReserveClick(indey, index)">
                  <!-- {{  getLineItem(indey, index).status}} -->
                  <!--休息/空闲-->
                  <div v-if="getLineItem(indey, index).status == 4" class="reserve-item restTime" :style="{
                    height: getH() + 'px',
                    top: indey * getH() + 'px',
                    left: index * 112 + 'px',
                  }"></div>

                  <!-- 有排班 && 空闲 -->
                  <div class="dialog" v-if="getLineItem(indey, index).status == 0 && currentTimeIndex == indey &&
                    currentStaffIndex == index && isShowDialog && getLineItem(indey, index).scheduling">
                    <div @click.stop="handleAddTimeOccupyClick(indey, index)">
                      新建占用
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </template>
        <!--订单信息 1待到店2已到店3已开单4已完成5已取消6已退款-->
        <template v-if="orderArr.length">
          <template v-for="(odr, idx) in orderArr" :key="idx">
            <div v-if="odr.content.isOccupancy" class="order-item" :class="{ 'occupy': odr.content.isOccupancy }"
              @click="openOrderPopup(odr.content)" :style="{
                height: odr.location.h * getH() + 'px',
                top: odr.location.y * getH() + 'px',
                left: odr.location.x * 112 + 'px',
              }">
              <div class="order-details">
                已占用
              </div>
            </div>
            <div v-else class="order-item" :class="'status-' + odr.content.orderInfo.orderStatus"
              @click="openOrderPopup(odr.content)" :style="{
                height: odr.location.h * getH() + 'px',
                top: odr.location.y * getH() + 'px',
                left: odr.location.x * 112 + 'px',
              }">
              <div class="order-details">
                <p class="tips">
                  {{ odr.content.orderInfo.customerName }}
                  <span class="ispoint" v-if="odr.content.orderInfo.customerType == 1">新客</span>
                  <span class="ispoint" v-if="odr.content.orderInfo.customerType == 2">回客</span>
                </p>
                <p>{{ odr.content.orderInfo.customerPhone }}</p>
                <p>{{ odr.content.orderInfo.serverName }}</p>
              </div>
            </div>
          </template>
        </template>
      </div>

    </div>


    
  </div>
 
     <add-reserve-dialog v-if="addReserveDialogVisible" :addInfo="addInfo" :dialogVisible="addReserveDialogVisible"
      @changeDialogVisible="changeAddReserveDialog">
    </add-reserve-dialog>
   
    <add-time-occupy-dialog :addInfo="addInfo" :dialogVisible="addTimeDialogVisible"
      @changeDialogVisible="changeAddTimeDialog"></add-time-occupy-dialog>
  <reserve-edit-draw v-if="pageEditReserveDialogVisible" :isCheck="true" :reserve="currentReserve"
      @changedDrawer="handleReserveEditdDrawer" :dialogVisible="pageEditReserveDialogVisible"></reserve-edit-draw> 
    <el-drawer v-model="dialogVisible" size="20%">
      <order-card :currentDialog="currentDialog" :roomOptions="roomOptions" @confirmAppAction="confirmAppAction"
        @handleReserveDetailClick="handleReserveDetailClick" @makeABillAction="makeABillAction"></order-card>
    </el-drawer>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import addReserveDialog from '../base-ui/add-reserve-dialog/add-reserve-dialog'
import cache from '@/utils/cache'
import emitter from '@/utils/eventbus'
import addTimeOccupyDialog from '../base-ui/add-time-occupy-dialog/add-time-occupy-dialog.vue'
import dayjs from 'dayjs'
import reserveEditDraw from '../../../list/cpns/reserve-list/cpns/base-ui/reserve-edit-draw/reserve-edit-draw.vue'
import bottomTips from '../../cpns/base-ui/bottom-tips/bottom-tips.vue'
// import { reserveTimeList_30, reserveTimeList_60, reserveTimeList } from '../config'
import { bedTime, getShopTime, getNewRooms,getBedListByOrder, confirmApp, makeABill } from '@/service/main/reserve'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import OrderCard from "../../components/card.vue"

export default defineComponent({
  props: {

  },
  components: {
    addReserveDialog,
    addTimeOccupyDialog,
    reserveEditDraw,
    bottomTips,
    OrderCard
  },
  emits: ["roomReserveList"],
  setup (props, { emit }) {
    const router = useRouter()
    const store = useStore()
    const orderArr = ref([])
    const pageInfo = reactive({
      page: 1,
      pagesize: 10
    })
    const dialogVisible = ref(false)
    const currentDialog = ref(null)
    const confirmAppParams = reactive({
      "appNo": "",
      "companyId": COMPANY_ID,
      "roomId": "",
      "arvTime": "",
      "arvKind": "1", // 1.本人，2他人 默认1
      "arvPerson": "",
      "bedId": ""
    })
    const bedOptions = ref([])
    const roomOptions = ref([])
    const handleCurrentChange = page => {
      pageInfo.page = page
    }

    const handleSizeChange = pagesize => {
      pageInfo.pagesize = pagesize
    }

    const roomChage = (e) => {
      const index = roomOptions.value.findIndex(r => r.roomId == e)
      bedOptions.value = roomOptions.value[index].bedIds
    }

    const handleOpenKaidanClick = () => {
      router.push({
        path: '/main/order/billing',
        query: {
          userId: currentDialog.value.orderInfo.userId,
          orderNo: currentDialog.value.orderInfo.orderNo,
          phone: currentDialog.value.orderInfo.customerPhone
        }
      })
    }

    const openOrderPopup = (item) => {
      if (![1, 2, 4].includes(item.orderInfo.orderStatus)) return
      currentDialog.value = item
      if(!item.isOccupancy){
        getBedListByOrder(currentDialog.value.orderInfo.orderNo).then(res => {
          // roomOptions.value = res.data.list
          roomOptions.value = res.data.map(t=>({
            ...t,
            enabled:t.bedList.some(b=>b.enabled)
          }))
          dialogVisible.value = true
        })
      }else{
        dialogVisible.value = true
      }
    }
    const handleCloseDialog = () => {
      dialogVisible.value = false
    }

  

    const ruleForm = reactive({
      shopId: '',
      date: dayjs(new Date()).format('YYYY-MM-DD'),
      timeDiff: 10
    })

    emitter.on('refreshRoomListTimediff', value => {
      ruleForm.timeDiff = value
      initPage()
    })

    const currentReserve = ref('')
    const pageEditReserveDialogVisible = ref(false)
    const handleReserveEditdDrawer = (flag) => {
      pageEditReserveDialogVisible.value = flag
    }
    const handleReserveDetailClick = (item) => {
      currentReserve.value = item.orderInfo
      pageEditReserveDialogVisible.value = true
    }

    const wrapHeight = ref()
    const setWrapHeight = () => {
      let diff = cache.getCache('reserveTimeDiff')
      let height
      if (diff == 10) {
        height = 6365
      } else if (diff == 30) {
        height = 1962
      } else {
        height = 1007
      }

      wrapHeight.value = height
      if (diff != null) {
        ruleForm.timeDiff = diff
      }

    }

    const initPage = () => {
      setWrapHeight()
      let adminInfo = cache.getCache('adminInfo')
      ruleForm.shopId = adminInfo.shopId
      store.dispatch('reserve/getReserveRoomListAction', { ...ruleForm })
    }
    initPage()
    const reserveTimeList = ref([])
    const roomReserveList = ref([])

    const refreshShopTime = async (shopId) => {
      const times = await getShopTime(shopId)
      reserveTimeList.value = times.data.map((i, idx) => {
        let arr = i.time.split(':');
        return {
          ...i,
          thirty: arr[1] == '30' || arr[1] == '00',
          sixty: arr[1] == '00'
        }
      })
    }
    emitter.on('currentTabsChange', type => {
      // console.log('type!!!!', type)
      if (type === 'room-list') {
        refreshBed({
          date: ruleForm.date,
          shopId: ruleForm.shopId
        })
        refreshShopTime(ruleForm.shopId)

      }
    })

    const refreshBed = (data) => {
      bedTime(data).then(res => {
        roomReserveList.value = res.data.list
        let obj = {}
        roomReserveList.value.forEach((item, index) => {
          item.timeList.forEach((t, i) => {
            let { orderNo } = t
            if (orderNo) {
              if (obj[orderNo]) {
                obj[orderNo].timeChild.push({
                  pIndex: index,
                  sIndex: i
                })
              } else {
                let o = {
                  content: { ...t, isOccupancy: false },
                  timeChild: [{
                    pIndex: index,
                    sIndex: i
                  }]
                }
                obj[orderNo] = o
              }
            }
          })
        })
        for (let key in obj) {
          let arr = obj[key].timeChild
          obj[key].location = {
            x: arr[0].pIndex,
            y: arr[0].sIndex,
            h: arr.length
          }
        }
        // console.log('处理之后的房间信息：', obj)
        orderArr.value = Object.values(obj)
        emit('roomReserveList', roomReserveList.value)
      })
    }
    emitter.on('changeRoomAddReserveShopId', shopId => {
      // console.log('room  shop')
      ruleForm.shopId = shopId
      refreshBed({
        date: ruleForm.date,
        shopId: shopId
      })
      // store.dispatch('reserve/getReserveRoomListAction', { ...ruleForm })
    })

    emitter.on('changeRoomListReserveTime', time => {
      // console.log('room  time')
      ruleForm.date = time
      refreshBed({
        date: time,
        shopId: ruleForm.shopId
      })
      // store.dispatch('reserve/getReserveRoomListAction', { ...ruleForm })
    })

    emitter.on('changeReserveRoomListPageContent', () => {
      store.dispatch('reserve/getReserveRoomListAction', { ...ruleForm })
    })

    watch(pageInfo, () => {
      initPage()
    })


    // const roomList = computed(() => store.state.reserve.roomList)
    // const roomTimeList = computed(() => store.state.reserve.roomTimeList)
    // const roomReserveStateInfo = computed(() => store.state.reserve.roomReserveStateInfo)

    // // console.log('房间----roomTimeList',roomReserveList)

    const currentStaffIndex = ref('')
    const handleReserveClick = (ele, item, index) => {
      addInfo.teacherId = ele.userId
      addInfo.startTime = item.time
      currentStaffIndex.value = index
    }

    const currentTimeIndex = ref('')
    const handleTimeClick = index => {
      currentTimeIndex.value = index
      isShowDialog.value = !isShowDialog.value

    }

    const isShowDialog = ref(false)

    const handleAddReserveClick = () => {
      addReserveDialogVisible.value = true
    }

    const addReserveDialogVisible = ref(false)
    const changeAddReserveDialog = (flag) => {
      addReserveDialogVisible.value = flag
    }


    const addInfo = reactive({
      teacherId: '',
      startTime: ''
    })


    const sraffNameRef = ref()
    const handleScroll = (e) => {
      if (sraffNameRef.value == undefined) return
      let scrollTop = e.target.scrollTop

      if (scrollTop > 280) {
        if (sraffNameRef.value.classList.contains('staffNameFiexed') == false) {
          sraffNameRef.value.classList.add('staffNameFiexed')
        }
      } else {
        if (sraffNameRef.value.classList.contains('staffNameFiexed') == true) {
          sraffNameRef.value.classList.remove('staffNameFiexed')
        }
      }

    }

    emitter.on('refreshPhysiotherapylistTimediff', diff => {
      ruleForm.timeDiff = diff
    })


    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
      emitter.off('refreshRoomListTimediff')
      emitter.off('changeRoomAddReserveShopId')
      emitter.off('changeRoomListReserveTime')
      emitter.off('changeReserveRoomListPageContent')
    })

    onMounted(() => {
      window.addEventListener('scroll', handleScroll, true)
    })

    const confirmAppAction = (e) => {
      // const {appNo,arvKind,arvPerson} = currentDialog.value.orderInfo
      // Object.assign(e, {
      //   arvTime:dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      //   appNo,
      //   arvKind,
      //   arvPerson
      // })
      confirmApp(e).then(res => {
        if (res.code == 0) {
          ElMessage({
            message: '操作成功!',
            type: 'success',
          })
          refreshBed({
            date: ruleForm.date,
            shopId: ruleForm.shopId
          })
          dialogVisible.value = false
        } else {
          ElMessage.error(res.msg)
          dialogVisible.value = false
        }
      })
    }

   
    const makeABillAction = () => {
      const { appNo, orderNo } = currentDialog.value.orderInfo
      makeABill({
        appNo,
        orderNo
      }).then(res => {
        if (res.code == 0) {
          ElMessage({
            message: '操作成功!',
            type: 'success',
          })

          dialogVisible.value = false

          if (res.data.orderPayStatus == 3) {
            handleOpenKaidanClick()
          } else {
            refreshBed({
              date: ruleForm.date,
              shopId: ruleForm.shopId
            })
          }
        } else {
          ElMessage.error(res.msg)
          dialogVisible.value = false
        }
      })
    }

    const getLineItem = (indey, index) => {
      return roomReserveList.value[index].timeList[indey]
    }
    const getH = ()=>{
      const diff = +ruleForm.timeDiff / 10
      return 72 / diff
    }
    return {
      getH,
      getLineItem,
      setWrapHeight,
      wrapHeight,
      sraffNameRef,
      ruleForm,
      // roomReserveStateInfo,
      handleReserveDetailClick,
      handleReserveEditdDrawer,
      pageEditReserveDialogVisible,
      currentReserve,
      // roomTimeList,
      // roomList,
      roomReserveList,
      addInfo,
      changeAddReserveDialog,
      addReserveDialogVisible,
      handleAddReserveClick,
      isShowDialog,
      currentStaffIndex,
      currentTimeIndex,
      handleTimeClick,
      handleReserveClick,
      pageInfo,
      handleSizeChange,
      handleCurrentChange,
      COMPANY_ID,
      reserveTimeList,
      orderArr,
      dialogVisible,
      currentDialog,
      openOrderPopup,
      handleCloseDialog,
      confirmAppParams,
      roomOptions,
      bedOptions,
      roomChage,
      confirmAppAction,
      makeABillAction
    }

  }
})
</script>

<style scoped lang="less">
.tips{
  position:relative;
  // padding-top: 18px;
}
.ispoint {
  display: inline-block;
  height: 16px;
  border-radius: 2px;
  border: 1px solid #fd678b;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  background-color: #fff;
  font-weight: 400;
  color: #fd678b;
  line-height: 16px;
  padding: 0 5px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
}

.bottom-date-bar {
  position: fixed;
  z-index: 1000;
  bottom: 10px;
  overflow-x: auto;

  .date-item {
    height: 1px !important;
  }
}

.diag-title {
  line-height: 30px;
}

.diag-select {
  margin-top: 20px;
  display: flex;

  .el-select {
    margin-right: 5px;
  }
}

// .title {
//   overflow: hidden;
// }

.title-wrap {
    // width: 100%;
    display: flex;
    margin-left: 80px;
    // position: relative;
    .date-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 55px;
    min-width: 112px;

    .date-week {
      margin-top: 6px;
      font-size: 13px;

    }

    div {
      width: 112px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(80, 80, 80, 1);
      font-size: 14px;
      // cursor: pointer;
    }
  }
  }

 

.pagination {
  text-align: right;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.line-box.paibanActive:nth-of-type(1) {
  border-left: 1px solid rgb(220, 220, 220);
}

.paibanActive {
  // height: 72px !important;
  border-right: 1px solid rgba(201, 197, 197, 1);
  background-color: white !important;
}

.shop-room {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &:deep(.el-select) {
    max-width: 146px;
  }
}

.staffNameFiexed {
  position: fixed;
  width: 88.5% !important;
  top: 180px;
  background-color: white;
  z-index: 100;
  border-bottom: 2px solid rgb(241, 243, 244);
}

.title {
  width: 100%;
  display: flex;
  // padding-left: 80px;

  // div {
  //   width: 112px;
  //   min-width: 112px;
  //   height: 55px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   color: rgba(80, 80, 80, 1);
  //   font-size: 14px;
  //   cursor: pointer;
  //   word-spacing: normal;
  //   font-weight: 700;
  // }
}

.physiotherapy-list {
  position: relative;
  padding-bottom: 250px;

  .reserve-dialog {
    position: absolute;
    z-index: 99;
    width: 343px;
    color: rgba(80, 80, 80, 1);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    padding: 20px;
    background-color: #fff;
    overflow: hidden;

    .opt-select {
      margin: 20px 0;

      &:deep(.el-select) {
        width: 100%;
      }
    }

    .caozuo-btn {
      display: flex;
      justify-content: space-between;

      &:deep(.button1) {
        flex: 1.5;
      }

      &:deep(.button2) {
        flex: 1;
      }
    }

    .genjin {
      position: relative;
      padding-bottom: 18px;

      &:deep(.el-input__inner) {
        height: 50px;
        line-height: 50px;
      }

      &:deep(.el-input__suffix) {
        top: 6px;
      }

      .genjin-text {
        position: absolute;
        width: 100%;
      }

      .submit {
        position: absolute;
        z-index: 100;
        right: 4px;
      }
    }

    .current-tips {
      color: rgba(80, 80, 80, 1);
      background-color: rgba(239, 239, 239, 1);
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 20px 0;

      div {
        line-height: 30px;
        text-align: center;
      }
    }

    .top {
      display: flex;
      justify-content: space-between;

      .btns {
        display: flex;
        flex-direction: column;

        &:deep(.el-tag) {
          margin-top: 10px;
        }

        .other {
          cursor: pointer;
          font-size: 12px;
          color: rgb(102, 177, 255);
          text-align: center;
        }

        .cancle {
          margin-top: 7px;
          width: 60px;
          height: 25px;
          background-color: rgba(255, 255, 255, 1);
          color: rgb(96, 123, 181);
          border-radius: 3px;
          font-size: 12px;
          text-align: center;
          line-height: 25px;
          cursor: pointer;
          box-sizing: border-box;
          border: 1px solid rgb(220, 223, 230);

          &:hover {
            border: 1px solid rgba(42, 130, 228, 1);
            color: rgba(42, 130, 228, 1);
          }
        }
      }

      .user-info {
        display: flex;

        .uname {
          .user-name {
            cursor: pointer;
            color: rgb(64, 158, 255);
          }

          .name {
            color: rgba(80, 80, 80, 1);
            font-size: 16px;
            display: flex;

            .isPoint {
              flex-shrink: 0;
              margin-left: 6px;
              width: 50px;
              height: 16px;
              border-radius: 2px;
              border: 1px solid #fd678b;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #fd678b;
              line-height: 16px;
              text-align: center;
            }
          }

          .phone {
            color: rgba(80, 80, 80, 1);
            font-size: 14px;
            margin-top: 4px;
          }
        }

        .avatar {
          margin-right: 10px;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .occupyTime {
    color: #fff;
    background-color: rgb(240, 183, 89);
  }

  .reserve-item {
    // border: 1px solid white;
    box-sizing: border-box;
    overflow: hidden;
    font-size: 12px;
    padding-top: 20px;
    cursor: pointer;

    &>div:nth-child(2) {
      margin-top: 2px;
    }
  }


  .restTime {
    // background: repeating-linear-gradient(50deg, #000, #000 5px, #fff 5px, #fff 12px);

    background: repeating-linear-gradient(45deg,
        #d3d3d3,
        #d3d3d3 2px,
        #e5e5e5 0,
        #e5e5e5 16px);
    background-repeat: repeat-y;
    // border-right: 1px solid rgb(201, 197, 197) !important;

    span {
      z-index: 999;
    }
  }

  .time {
    display: flex;
    width: 100%;
    position: relative;

    // overflow: hidden;
    .time-scale {
      position: absolute;
      z-index: 89;
      width: 80px;
      background-color: #fff;

      // display: flex;
      // flex-wrap: wrap;
      div {

        height: 72px;
        color: rgba(80, 80, 80, 1);
        font-size: 14px;
        text-align: center;
      }

      &>div:nth-child(4n + 1) {
        font-weight: 556;
      }
    }

    .line-list {
      position: absolute;
      z-index: 9;
      width: calc(100% - 80px);
      left: 80px;

      .order-item {
        position: absolute;
        // background: red;
        color: #fff;
        width: 112px;
        box-sizing: border-box;
        border: 1px solid #c9c5c5 !important;
        cursor: pointer;

        &.status-1 {
          background-color: rgb(121, 72, 234);
        }

        &.status-2 {
          background-color: rgb(36, 153, 84);
        }

        &.status-3 {
          background-color: rgb(42, 130, 228);
        }

        &.status-4 {
          background-color: rgb(19, 194, 194);
        }

        &.status-5 {
          background-color: rgb(240, 183, 89);
        }

        &.status-6 {
          background-color: rgb(227, 91, 91);
        }

        &.occupy {
          background-color: rgb(240, 183, 89) !important
        }

        .order-details {
          // padding: 6px;

          p {
            padding: 0 5px;
            word-break: break-all;
            color: #fff;
            font-size: 12px;
            line-height: 20px;
          }
        }

      }

      &.list-10{
        .line-box{
          &:first-child{
            .line-item{
              border-top: 1px solid rgb(201, 197, 197);
            }
          }
          .line-item{
            height: 72px;
            border-bottom: 1px solid rgb(201, 197, 197);
          }
        }
       
      }
      &.list-30{
        .line-box{
          &:first-child{
            .line-item{
              border-top: 1px solid rgb(201, 197, 197);
            }
          }
          &:nth-child(3n){
            .line-item{
              border-bottom: 1px solid rgb(201, 197, 197);
          }
          }
          .line-item{
            height: 24px;
          }
        }
      }

      &.list-60{
        .line-box{
          &:first-child{
            .line-item{
              border-top: 1px solid rgb(201, 197, 197);
            }
          }
          &:nth-child(6n){
            .line-item{
              border-bottom: 1px solid rgb(201, 197, 197);
          }
              
          }
          .line-item{
            height: 12px;
          }
        }
      }

      .line-box {
        display: flex;

        &.last div {
          border-bottom: 1px solid rgb(201, 197, 197);
        }

        &>div:nth-child(1) {
          border-left: 1px solid rgb(201, 197, 197);
        }

        .cursor {
          cursor: pointer;
        }

        .line-item {
          position: relative;
          width: 112px;
          min-width: 112px;
          height: 72px;
          overflow: hidden;
          // border-top: 1px solid rgb(201, 197, 197);
          background-color: rgb(245, 245, 245);
          border-right: 1px solid rgb(201, 197, 197);
          // padding-top: 1px;

          .dialog {
            display: flex;
            flex-direction: column;
            background-color: rgb(77, 77, 77);
            color: white;
            font-size: 13px;
            text-align: center;

            .empty-class {
              background-color: rgb(211, 211, 211);
              height: 72px;
              color: rgb(63, 60, 60);
              line-height: 72px;
            }

            div {
              line-height: 36px;
              cursor: pointer;
            }
          }
        }
      }

      &>div {
        height: 72px;
        z-index: 9;
        // border-top: 1px solid rgba(220, 220, 220, 1);
      }

      // &>.line-box:last-child div {
      //   border-bottom: 1px solid #c9c5c5;
      // }
    }
  }
}</style>




