<template>
    <div class="left_statistics" @click="goLog()">
      <p>目标客户：{{statisticsData.data.targetCustomerCount||0}}</p>
      <p>待计划：{{statisticsData.data.waitingPlanCount||0}}</p>
      <p>待复盘：{{statisticsData.data.waitingForReview||0}}</p>
    </div>
</template>

<script>
import { defineComponent, reactive, watch } from 'vue'

export default defineComponent({
  props: {
    statistics: {
      type: Object,
      default() {
        return {
          data:{
            targetCustomerCount:0,
            waitingForReview:0,
            waitingPlanCount:0
          }
        }
      }
    }
  },
  emits: ['toDetail'],
  setup(props, { emit }) {
    const statisticsData = reactive(
      {
        data:{
          targetCustomerCount:0,
          waitingForReview:0,
          waitingPlanCount:0
        }
      }
    )
    const goLog = ()=>{
      emit('toDetail')
    }
    watch(() => props.statistics, (n) => {
      console.log(n);
      // statisticsData.value = n
      statisticsData.data = n.data
    }, {
      deep: true
    })
    return {
      statisticsData,
      goLog
    }
  }
})
</script>

<style scoped lang="less">
.left_statistics{
    display: flex;
    justify-content: space-between;
    width: 300px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    // justify-content: flex-end;
  }
// .left_statistics{
//   border-radius: 4px;
//   position: fixed;
//   right: 58px;
//   bottom: 100px;
//   min-width: 300px;
//   height: 55px;
//   color: rgba(80, 80, 80, 1);
//   background-color: rgba(255, 255, 255, 0.8);
//   font-size: 14px;
//   box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.8);
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   z-index: 100;
//   padding: 0 10px;
//   cursor: pointer;

//   .item {
//     display: flex;
//     align-items: center;
//     margin-right: 10px;

//     .key {
//       margin-right: 2px;
//       width: 20px;
//       height: 20px;
//     }
//   }

//   .state1 {
//     background-color: rgb(121, 72, 234);
//   }

//   .state2 {
//     background-color: rgb(36, 153, 84);
//   }

//   .state3 {
//     background-color: rgb(42, 130, 228);
//   }

//   .state4 {
//     background-color: rgb(19, 194, 194);
//   }

//   .state5 {
//     background-color: rgb(240, 183, 89);
//   }

//   .state6 {
//     background-color: #fff;
//     box-sizing: border-box;
//     border: 1px solid #eee;
//   }

//   // .state6 {
//   //   background-color: #fa8c16;
//   // }
//   .state7 {
//     background-color: #e4e3e1;
//   }
// }
</style>
