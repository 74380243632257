<template>
    <div class="add-reserve-dialog">
        <page-dialog width="650px"
            @changeDialogVisible="changeDialogVisible"
            :dialogVisible="dialogVisible"
            title="添加占用"
            @handleSaveClick="handleSaveClick(ruleFormRef)">
            <el-form label-position="left"
                :rules="addOccupyTimeRules"
                ref="ruleFormRef"
                :model="occupyForm"
                label-width="120px"
                class="demo-ruleForm"
                status-icon>
                <el-form-item label="理疗师:">
                    {{ addInfo.teacherName }}
                </el-form-item>
                <el-form-item label="开始日期:"
                    prop="date">
                    <el-form-item prop="date">
                        <el-date-picker :disabled-date="disabledDate"
                            :clearable="false"
                            @change="handleDateChange"
                            v-model="occupyForm.date"
                            type="date"
                            placeholder="选择日期" />
                    </el-form-item>
                </el-form-item>
                <el-form-item label="起始时间:"
                    prop="startTime">
                    <el-select v-model="occupyForm.startTime" placeholder="请选择">
                      <el-option
                        v-for="(item,index) in teacherTimeList"
                        :key="index"
                        :label="item.time"
                        :value="item.time"
                        :disabled="item.isDisplay==false||item.timeStatus==2">
                      </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="endTime" label="结束时间">
                    <el-select v-model="occupyForm.endTime" placeholder="请选择">
                      <el-option
                        v-for="(item,index) in teacherTimeList"
                        :key="index"
                        :label="item.time"
                        :value="item.time"
                        :disabled="item.isDisplay==false||item.timeStatus==2">
                      </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="cause" label="原因">
                    <el-select v-model="occupyForm.cause" placeholder="请选择">
                      <el-option
                        v-for="(item,index) in OccupyTypeOptions"
                        :key="index"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="save-btn">
                <el-button @click="handleSaveClick(ruleFormRef)"
                    size="small"
                    type="primary">保存</el-button>
            </div>
        </page-dialog>
    </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch, onUnmounted } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { tips } from '@/utils/tips'
import { COMPANY_ID, FORM_EMPTY_ERROR_TIPS } from '@/common/constant'
import { useState } from '@/hooks'
import emitter from '@/utils/eventbus'
import cache from '@/utils/cache'
import ruleFormVue from '@/views/main/reserve/site/base-ui/rule-form/rule-form.vue'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import { isSchedule, setTeacherTimeOccupy,getUserTimeList } from '@/service/main/reserve'
import { addOccupyTimeRules } from '../../../config'
import {OccupyType} from '../../../config/occupyType.js'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    addInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    teacherTimeList:{
      type:Array,
      default(){
        return []
      }
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible', 'refreshOccupyTime','changeDate'],
  setup(props, { emit }) {
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }
    const OccupyTypeOptions = new OccupyType().toObjectArray();
    const reserveTimeList = computed(() => store.state.reserve.reserveTimeList)
    const ruleForm = reactive({
      appNo: "YYYYMMDDHIS",
      companyId: COMPANY_ID,
      userId: "",
      serveName: '',
      serveId: '',
      paraId: '',
      shopId: "",
      arriveDate: new Date(),
      arriveTime: "",
      appTimes: "",
      empId: "",
      prepay: 0,
      prepayAmount: 0,
      promPrice: 0,
      appStatus: 1,
      appMemo: "",
      isPoint: 0,
      servePrice: "",
      serveTimeLength: "",
      arriveKind: 1,
      arrivePerson: '',
      userMobile: "",
      toShopPreson: "",
    })

    const occupyForm = reactive({
      date: cache.getCache('optReserveDate'),
      startTime: "",
      minute: "",
      hour: "",
      dateTime: cache.getCache('optReserveDateTime')
    })

    const oldTimeStart = ref(null)
    const handleDateChange = value => {
      let oldTime = oldTimeStart.value
      occupyForm.startTime = ''
      let date = dayjs(value).format('YYYY-MM-DD')
      let currentDate = dayjs(new Date())

      let currentValue = currentDate.valueOf()
      let optValue = dayjs(value).valueOf()
      let diff = currentValue - optValue
      let dateTime
      // 如果当前选的是未来的日期
      if (diff < 0) {
        dateTime = dayjs(value).format('YYYY-MM-DD HH:mm:ss')
      } else {
        dateTime = dayjs(date + oldTime).format('YYYY-MM-DD HH:mm:ss')
      }
      occupyForm.dateTime = dateTime
      getTeacherTime()
    }
    const getTeacherTime = async () => {
      let arriveDate = dayjs(occupyForm.date).format('YYYY-MM-DD')
      let empId=props.addInfo.teacherId;
      const res = await getUserTimeList({empId, date:arriveDate,shopId:props.addInfo.shopId})
      if(res.data&&res.data.length){
          res.data.map(item=>{
            item.time=item.time.split(':')[0]+':'+item.time.split(':')[1]
          })
          emit('changeDate',res.data)
      }
    }


    // 设置选择的开始时间
    const initStartTime = (type = 0) => {
      let optarrive
      // 0初始化  1其他  调用
      if (type == 0) {
        optarrive = props.addInfo.startTime
      } else {
        optarrive = '07:00'
      }

      let date1 = dayjs(occupyForm.date).format('YYYY-MM-DD')
      let date2 = dayjs(new Date()).format('YYYY-MM-DD')
      let optdate = dayjs(date1).valueOf()
      let curdate = dayjs(date2).valueOf()

      // 如果是当天
      if (optdate == curdate) {
        let date3 = dayjs(date1 + optarrive).valueOf()
        let date4 = dayjs(new Date()).valueOf()
        if (date3 > date4) {
          occupyForm.startTime = optarrive
        }
      } else {
        occupyForm.startTime = optarrive
      }
    }
    initStartTime()

    const minuteArr = [
      {
        label: '0分钟',
        value: 0
      },
      {
        label: '15分钟',
        value: 15
      },
      {
        label: '30分钟',
        value: 30
      },
      {
        label: '45分钟',
        value: 45
      }
    ]

    const minuteArr2 = [
      {
        label: '15分钟',
        value: 15
      },
      {
        label: '30分钟',
        value: 30
      },
      {
        label: '45分钟',
        value: 45
      }
    ]


    const occupyMinute = ref(minuteArr)


    // 设置可选时间的开始
    const timeStart = computed(() => {
      let oldTime = oldTimeStart.value
      let mm = dayjs(occupyForm.dateTime).format('mm')
      let HH = dayjs(occupyForm.dateTime).format('HH')
      if (mm > 0 && mm < 15) {
        mm = '15'
      }
      else if (mm > 15 && mm < 30) {
        mm = '30'
      }

      else if (mm > 30 && mm < 45) {
        mm = '45'
      }

      else if (mm > 45 && mm <= 59) {
        mm = '00'
        HH = Number(HH) + 1
      }


      if (String(HH) == '00') {
        HH = '07'
      }

      let time = String(HH) + ':' + String(mm)

      if (oldTime == null) {
        oldTimeStart.value = time
      }
      return time
    })

    watch(() => props.addInfo, (n) => {
      ruleForm.empId = n.teacherId
      ruleForm.arriveTime = n.startTime
    }, {
      deep: true
    })

    const shopTeacherList = computed(() => store.state.reserve.shopTeacherList)
    const checkList = ref([])
    const currentOptServe = reactive({
      serveName: '',
      serveId: "",
      flag: false,
      paraId: ""
    })
    const handleServeClick = (item) => {
      currentOptServe.serveName = item.serveName
      currentOptServe.serveId = item.serveId
      ruleForm.serveName = item.serveName
      ruleForm.serveId = item.serveId
    }

    const handleCheckBoxChange = (value) => {
      currentOptServe.flag = value
    }

    const occupyHour = ref([])
    const handleStartTimeChange = value => {
      occupyForm.endTime = ""
      // let list = []
      // let date1 = dayjs('2022-12-12' + value)
      // let date2 = dayjs('2022-12-12' + '24:00')
      // let diffHour = date2.diff(date1, 'hour')
      // for (let i = 0; i <= diffHour; i++) {
      //   list.push({
      //     label: `${i}小时`,
      //     value: i
      //   })
      // }
      // occupyHour.value = list
    }

    const handleHourChange = value => {
      occupyForm.minute = ''
      let startTime = occupyForm.startTime
      let hour = occupyForm.hour

      if (startTime == '23:00' && hour == 1) {
        occupyMinute.value = [{
          label: '0分钟',
          value: 0
        }]
      } else if (hour == 0) {
        occupyMinute.value = minuteArr2
      } else {
        occupyMinute.value = minuteArr
      }
    }

    watch(() => occupyForm.startTime, (n) => {
      handleStartTimeChange(n)
    }, {
      deep: true,
      immediate: true
    })

    const disabledDate = (time) => {
      return time.getTime() < Date.now() - 8.64e7
    }


    const handleSaveClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          let {startTime,endTime,cause,date} = occupyForm
          // 判断这个理疗师是否排班
          const flagRes = await isSchedule({
            empId: props.addInfo.teacherId,
            date,
            startTime,
            endTime,
            cause,
            shopId:props.addInfo.shopId
          })
          if (flagRes.code == 0) {
            ElMessage({
              message: '操作成功!',
              type: 'success',
            })
            emit('refreshOccupyTime')
            changeDialogVisible(false)
          }else{
            ElMessage({
              message: flagRes.msg,
              type: 'error',
            })
          }
        } else {
          ElMessage({
            message: FORM_EMPTY_ERROR_TIPS,
            type: 'warning',
          })
        }
      })
    }

    const ruleFormRef = ref()
    onUnmounted(() => {
      emitter.off('changeAddTimeOccopyDialogTime')
    })

    const initPage = () => {
      let currentOptDate = cache.getCache('optReserveDate')
      occupyForm.date = currentOptDate
      store.dispatch('reserve/getShopListAction', COMPANY_ID)
      let adminInfo = cache.getCache('adminInfo')
      ruleForm.shopId = adminInfo.shopId
      // ruleForm.shopId = props.addInfo.shopId
      store.dispatch('reserve/getShopTeacherListAction', ruleForm.shopId)
    }
    // initPage()

    return {
      FORM_EMPTY_ERROR_TIPS,
      addOccupyTimeRules,
      disabledDate,
      oldTimeStart,
      handleDateChange,
      occupyMinute,
      handleHourChange,
      occupyHour,
      occupyHour,
      handleStartTimeChange,
      ruleFormRef,
      timeStart,
      initStartTime,
      occupyForm,
      reserveTimeList,
      dayjs,
      handleSaveClick,
      handleCheckBoxChange,
      handleServeClick,
      currentOptServe,
      checkList,
      ruleForm,
      changeDialogVisible,
      COMPANY_ID,
      shopTeacherList,
      OccupyTypeOptions,
      getTeacherTime
    }

  }
})
</script>

<style scoped lang="less">
.add-reserve-dialog {
  &:deep(.el-form-item__content) {
    display: flex;
  }

  .save-btn {
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
  }

  .time-list {
    padding: 10px;

    &:deep(.el-checkbox) {
      display: block;
    }
  }

  &:deep(.el-form-item__content) {
    .el-input {
      margin-right: 10px;
      width: 220px;
    }

    .el-select {
      margin-right: 10px;
      width: 220px;
    }
  }

  .item {
    display: flex;
    font-size: 16px;
    margin: 20px 0;

    .value {
    }

    .key {
      font-size: 12px;
      min-width: 90px;
    }
  }
}
</style>
