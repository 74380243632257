<template>
  <div ref="contentRef" class="content">
    <div ref="topFormRef" class="top-content">
      <div class="left">
        <el-select @change="handleShopChange" v-model="ruleForm.shopId" class="m-2" placeholder="选择门店" size="large">
          <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName" :value="item.shopId">
          </el-option>
        </el-select>
        <div class="flex_date">
          <el-icon class="el-icon--left" @click="datedown"><ArrowLeft /></el-icon>
          <el-date-picker style="margin: 0 4px;" @change="handleTimeChange" v-model="ruleForm.date" type="date" placeholder="选择日期">
          </el-date-picker>
          <el-icon class="el-icon--right" @click="dateup"><ArrowRight /></el-icon>
        </div>  
        <el-select @change="handleTimeGapChange" v-model="ruleForm.timeGap" class="time-gap-select" placeholder="选择时间颗粒度" size="large">
          <el-option v-for="item in timeGapOption" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>

      <div class="right">
        <span>切换预览方式：</span>
        <div :class="{ 'active-icon': currentTabs == 'physiotherapy-list' }" @click="handleIconClick('physiotherapy-list')" class="icon">
          <el-icon>
            <female />
          </el-icon>
        </div>

        <div :class="{ 'active-icon': currentTabs == 'room-list' }" @click="handleIconClick('room-list')" class="icon">
          <el-icon>
            <document-copy />
          </el-icon>
        </div>
      </div>
    </div>
    <!-- :style="{ height: mainHeight.height1+150 + 'px' }" -->
    <div class="main-content">
      <div v-show="currentTabs == 'physiotherapy-list'">
        <physiotherapy-list @reservationBoardList="setReservationBoardList" :contentWidth="contentWidth" @changeAddReserve="changeAddReserve"></physiotherapy-list>
      </div>
      <div v-show="currentTabs == 'room-list'">
        <room-list @roomReserveList="setroomReserveList"></room-list>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch, onUnmounted, onMounted } from 'vue'
import PhysiotherapyList from '../physiotherapy-list/physiotherapy-list.vue'
import RoomList from '../room-list/room-list.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import cache from '@/utils/cache'
import emitter from '@/utils/eventbus'
import dayjs from 'dayjs'
import { timeGapOption } from '../config'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {

  },
  components: {
    PhysiotherapyList,
    RoomList
  },
  emits: ['currentTabsChange', 'shopChnage'],
  setup (props, { emit }) {

    const router = useRouter()
    const reservationBoardList = ref([])
    const roomReserveList = ref([])

    const setReservationBoardList = (e) => {
      reservationBoardList.value = e
    }
    const store = useStore()
    const currentTabs = ref('physiotherapy-list')

    const handleIconClick = (type) => {
      currentTabs.value = type
      emit('currentTabsChange', type)
      emitter.emit('currentTabsChange', type)

    }

    const topFormRef = ref()
    const ruleForm = reactive({
      shopId: '',
      date: new Date(),
      timeGap: '10'
    })


    const handleShopChange = (value) => {
      // debugger
      cache.setCache('selectShopId', value)
      emitter.emit('changeAddReserveShopId', value)
      emitter.emit('changeRoomAddReserveShopId', value)
      emitter.emit('changeAddReserveDialogShopId', value)
    }

    const shopList = computed(() => store.state.reserve.shopList)
    watch(() => shopList, (n,o) => {
    if (n?.value.length > 0) {
		let selectShopId = cache.getCache('selectShopId')
    // debugger
		if (selectShopId) {
			let index = shopList.value.findIndex((ele) => ele.shopId == selectShopId);
			ruleForm.shopId = selectShopId
			if (index != -1) {
        handleShopChange(selectShopId)
        return
			}
    }

		let adminInfo = cache.getCache('adminInfo')
        const findShopOne = n.value.find(item => adminInfo.shopId == item.shopId)
        ruleForm.shopId = findShopOne ? adminInfo.shopId : n.value[0].shopId
        handleShopChange(ruleForm.shopId)
          emitter.emit('reserveTopcontentShopIdChange', ruleForm.shopId)
      }
    }, { immediate: true, deep: true })
    const handleTimeChange = (value) => {
      let date = dayjs(value).format('YYYY-MM-DD')
      let dateTime = dayjs(value).format('YYYY-MM-DD HH:mm:ss')
      cache.setCache('optReserveDate', date)
      cache.setCache('optReserveDateTime', dateTime)
      emitter.emit('changeAddReserveTime', date)
      emitter.emit('changeAddReserveDialogTime', date)
      emitter.emit('changeAddTimeOccopyDialogTime', date)
      emitter.emit('changeRoomListReserveTime', date)
      emitter.emit('changeTeacherDetailReserveTime', date)
    }

    const isScroll = ref(true)
    const changeAddReserve = flag => {
      isScroll.value = !flag
    }

    // const handleScroll = (e) => {
    //   if (topFormRef.value == undefined || isScroll.value == false) return
    //   let scrollTop = e.target.scrollTop
    //   if (scrollTop != 0) {
    //     if (scrollTop > 280) {
    //       if (topFormRef.value.classList.contains('topformFiexed') == false) {
    //         topFormRef.value.classList.add('topformFiexed')
    //       }
    //     }
    //     else {
    //       if (topFormRef.value.classList.contains('topformFiexed') == true) {
    //         topFormRef.value.classList.remove('topformFiexed')
    //       }
    //     }
    //   }

    // }


   

    const setroomReserveList = (v) => {
      roomReserveList.value = v
    }

    onUnmounted(() => {
      // window.removeEventListener('scroll', handleScroll)
    })
    const contentWidth = ref(1200)
    const contentRef = ref()

    onMounted(() => {
      // window.addEventListener('scroll', handleScroll, true)
      contentWidth.value = contentRef.value.offsetWidth

    })


    const mainHeight = reactive({
      height1: 6350
    })
    const datedown = () =>{
      ruleForm.date = dayjs(ruleForm.date).subtract(1, 'day')
      handleTimeChange(ruleForm.date)
    }
    const dateup = () =>{
      ruleForm.date = dayjs(ruleForm.date).add(1, 'day')
      handleTimeChange(ruleForm.date)
    }

    const handleTimeGapChange = value => {
      cache.setCache('reserveTimeDiff', value)
      // emitter.emit('refreshRoomListTimediff', value)
      emitter.emit('refreshPhysiotherapylistTimediff', value)
      let height
      if (value == 10) {
        height = 6350
      } else if (value == 30) {
        height = 2742
      } else {
        height = 1564
      }

      mainHeight.height1 = height

    }
    watch(() => ruleForm.shopId, (n) => {
      emitter.emit('reserveTopcontentShopIdChange', n)
    })

    const initPage = () => {
      store.dispatch('reserve/getShopListAction', COMPANY_ID)
      let date = dayjs(new Date()).format('YYYY-MM-DD')
      let dateTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')

      cache.setCache('optReserveDate', date)
      cache.setCache('optReserveDateTime', dateTime)

      let reserveTimeDiff = cache.getCache('reserveTimeDiff')
      if (reserveTimeDiff != undefined) {
        ruleForm.timeGap = reserveTimeDiff
        handleTimeGapChange(reserveTimeDiff)
      }

    }
    initPage()


    return {
      roomReserveList,
      setroomReserveList,
      reservationBoardList,
      setReservationBoardList,
      contentRef,
      contentWidth,
      changeAddReserve,
      isScroll,
      topFormRef,
      mainHeight,
      handleTimeGapChange,
      handleShopChange,
      ruleForm,
      shopList,
      currentTabs,
      handleIconClick,
      COMPANY_ID,
      handleTimeChange,
      timeGapOption,
      datedown,
      dateup
    }

  }
})
</script>

<style scoped lang="less">
.title-wrap {
  width: 100%;
  display: flex;
  margin-left: 80px;
  position: relative;
}

.date-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  min-width: 112px;
  font-weight: 600;
  font-size: 14px;

  .date-week {
    margin-top: 6px;
    font-size: 13px;
  }

  div {
    width: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(80, 80, 80, 1);
    font-size: 14px;
  }
}

.topformFiexed {
  position: fixed;
  width: 90% !important;
  top: 120px;
  background-color: white;
  z-index: 900;
  border-bottom: 1px solid rgb(241, 243, 244);
  padding: 10px 0;

  + .main-content {
    :deep(.teacher-title),
    :deep(.room-title) {
      position: fixed;
      top: 180px;
      background-color: white;
      z-index: 900;
    }
  }
}

.content {
  overflow: hidden;

  &:deep(.time-gap-select) {
    margin-left: 10px;
  }

  .main-content {
    margin-top: 20px;
    position: relative;
    // overflow-y: scroll;
  }

  .top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      .add-btn {
        margin-right: 10px;
      }

      &:deep(.el-select) {
        margin-right: 10px;
      }
      .flex_date{
        display: flex;
        align-items: center;
        .el-icon--left,.el-icon--right{
          cursor: pointer;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .active-icon {
        border: 1px solid rgba(255, 87, 51, 1) !important;

        i {
          color: rgba(255, 87, 51, 1);
        }
      }

      .icon {
        cursor: pointer;
        padding: 6px;
        margin: 0 6px;
        border: 1px solid rgba(166, 166, 166, 1);

        i {
          font-size: 20px;
        }
      }
    }
  }
}
</style>
